import React, { useState } from 'react';
import Link from "next/link";
import { HiX, HiMenu } from "react-icons/hi";

export default function Nav() {
	const [showMobileMenu, setShowMobileMenu] = useState(false);

	return (
		<>
			<nav className="bg-white z-50">
				<div className="max-w-6xl mx-auto px-2 sm:px-6 lg:px-8 font-primary fixed sm:relative w-full bg-white z-50 shadow sm:shadow-none shadow-md">
					<div className="relative flex items-center justify-between h-16 sm:h-20 z-50 bg-white">
						<Link href='/'>
							<a className='text-2xl font-bold'>
								<img src='/img/logo.png' className="w-40 self-start ml-1" alt='logo' />
							</a>
						</Link>
						<div className="hidden md:block">
							<ul className="flex flex-row website-main-nav">
								<li>
									<a className="px-3 py-2 text-gray-700 hover:text-gray-900 transition duration-500 ease-in-out hover:rounded hover:bg-gray-200 rounded"
										href="/#features">
										על האפליקציה
									</a>
								</li>
								<li>
									<a className="px-3 py-2 text-gray-700 hover:text-gray-900 transition duration-500 ease-in-out hover:rounded hover:bg-gray-200 rounded"
										href="/pricing/">
										מחירים
									</a>
								</li>
								<li>
									<a className="px-3 py-2 text-gray-700 hover:text-gray-900 transition duration-500 ease-in-out hover:rounded hover:bg-gray-200 rounded"
										href="/#faq">
										שאלות נפוצות
									</a>
								</li>
								<li>
									<a className="px-3 py-2 text-gray-700 hover:text-gray-900 transition duration-500 ease-in-out hover:rounded hover:bg-gray-200 rounded"
										href="https://zmantov.tawk.help/" target="_blank">
										מדריכים
									</a>
								</li>
								<li>
									<button onClick={() => window.$crisp.push(["do", "chat:open"])}
										className="flex px-3 py-2 text-gray-700 hover:text-gray-900 transition duration-500 ease-in-out hover:rounded hover:bg-gray-200 rounded items-center contact-nav-btn">
										צור קשר
									</button>
								</li>
								<li>
									<a className="px-3 py-2 text-gray-700 hover:text-gray-900 transition duration-500 ease-in-out hover:rounded hover:bg-gray-200 rounded"
										href="/updates">
										מה חדש
									</a>
								</li>
							</ul>
						</div>
						<div className="hidden md:block">
							<ul className="flex">
								<li>
									<Link href="/app/login/">
										<a className="text-green-500 px-4 py-2 border-2 border-green-500 hover:bg-green-500 hover:text-white transition duration-500 font-semibold ease-in-out hover:rounded rounded">
											כניסה
										</a>
									</Link>
								</li>
								<li className="nav-item px-2 signup">
									<Link href="/app/signup/">
										<a className="text-white px-3 py-2 bg-green-500 hover:bg-green-600 hover:border-green-600 rounded border-2 border-green-500 font-semibold transition duration-500 ease-in-out cursor-pointer">
											הצטרפות
										</a>
									</Link>
								</li>
							</ul>
						</div>

						<div onClick={() => setShowMobileMenu(!showMobileMenu)}
							className="hamburger-menu px-2 py-1 block md:hidden rounded uppercase">
							<HiMenu size={30} />
						</div>

						<div
							className={`px-0 mx-0 ${showMobileMenu ? '' : 'hidden'} md:hidden fixed top-0 left-0 w-full bg-white h-screen h-full fixed z-50`}>
							<div className="flex justify-between px-2 py-2">
								<Link href="/#main">
									<a onClick={() => setShowMobileMenu(!showMobileMenu)}>
										<img src='/img/logo.png' className="w-40 self-start ml-1" alt='logo' />
									</a>
								</Link>

								<div onClick={() => setShowMobileMenu(!showMobileMenu)}
									className="close-menu flex items-center content-center justify-center p-2 px-2 text-gray-900 rounded text-white uppercase">
									<HiX size={30} />
								</div>
							</div>
							<ul className="flex flex-col text-center mt-2 pt-2 w-full p-3">
								<li className="active w-full">
									<Link href="/#main">
										<a onClick={() => setShowMobileMenu(!showMobileMenu)}
											className="w-full text-lg border-t border-gray-200 block py-3">
											ראשי
										</a>
									</Link>
								</li>
								<li className="w-full">
									<Link href="/#features">
										<a onClick={() => setShowMobileMenu(!showMobileMenu)}
											className="w-full text-lg border-t border-gray-200 block py-3">
											על האפליקציה
										</a>
									</Link>
								</li>
								<li className="w-full">
									<Link href="/updates">
										<a onClick={() => setShowMobileMenu(!showMobileMenu)}
											className="w-full text-lg border-t border-gray-200 block py-3">
											מה חדש
										</a>
									</Link>
								</li>
								<li className="w-full">
									<Link href="https://zmantov.tawk.help/" passHref>
										<a target="_blank" rel="noopener noreferrer" onClick={() => setShowMobileMenu(!showMobileMenu)}
											className="w-full text-lg border-t border-gray-200 block py-3">
											מדריכים
										</a>
									</Link>
								</li>
								<li className="w-full">
									<Link href="/pricing/">
										<a onClick={() => setShowMobileMenu(!showMobileMenu)}
											className="w-full text-lg border-t border-gray-200 block py-3">
											מחירים
										</a>
									</Link>
								</li>
								<li className="w-full">
									<Link href="/#faq">
										<a onClick={() => setShowMobileMenu(!showMobileMenu)}
											className="w-full text-lg border-t border-gray-200 block py-3">
											שאלות נפוצות
										</a>
									</Link>
								</li>
								<li className="w-full mt-5">
									<Link href="/app/login/">
										<a className="w-full text-lg block py-3 text-green-500 border border-green-500 hover:bg-green-500 hover:text-white transition duration-500 font-semibold ease-in-out hover:rounded rounded">
											כניסה
										</a>
									</Link>
								</li>
								<li className="w-full mt-3">
									<Link href="/app/signup/">
										<a className="w-full text-lg block py-3 text-white px-3 py-2 bg-green-500 hover:bg-green-600 rounded border border-green-500 shadow font-semibold transition duration-500 ease-in-out cursor-pointer">
											הצטרפות
										</a>
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</nav>
		</>
	);
}
