import React from 'react';
import Nav from "./Nav"
import Link from "next/link";
import Head from 'next/head'

function Layout({ children }) {
	return (
		<>
			<Head>
				<title key='maintitle'>זמן טוב - האפליקציה המתקדמת ביותר לניהול תורים ולקוחות לעסק שלך</title>
				<meta property="og:title" key='ogtitle' content="זמן טוב - ניהול תורים ולקוחות לעסק שלך" />
				<meta name="description" key='description' content="האפליקציה המתקדמת, היעילה והחכמה ביותר לעסקים הדורשים ניהול לקוחות וזימון תורים" />
				<meta property="og:description" key='ogdescription' content="האפליקציה המתקדמת, היעילה והחכמה ביותר לעסקים הדורשים ניהול לקוחות וזימון תורים" />
				<link rel="apple-touch-icon" sizes="180x180" key="icon1" href="/img/favicons/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" key="icon2" href="/img/favicons/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" key="icon3" href="/img/favicons/favicon-16x16.png" />
				<link rel="manifest" key='manifest' href="/img/favicons/site.webmanifest" />
			</Head>
			<div>
				<Nav />
				<main className='pt-16'>
					{children}
				</main>
				{/*footer*/}
				<footer>
					<div
						className='bg-gray-100 text-center p-3 text-sm flex items-center justify-center flex-col pt-10 w-full border-t'>
						<div className='mb-5 text-center mx-auto'>
							<a href='https://zmantov.fra1.digitaloceanspaces.com/irs-certificate.pdf' target='_blank'>
								<img className='w-16 h-16 mx-auto' src='/img/irs-badge.svg' />
								<p className='mt-2 text-gray-600'>תוכנה רשומה (מס׳ רישום 243201)</p>
							</a>
						</div>

						<div className='mb-2'>
							<Link href='/app/signup/'>
								<a className='p-2'>הרשמה</a>
							</Link>
							<Link href='/app/login/'>
								<a className='p-2'>התחברות</a>
							</Link>
							<Link href='/#faq'>
								<a className='p-2'>שאלות נפוצות</a>
							</Link>
							<Link href='/pricing/'>
								<a className='p-2'>מחירון</a>
							</Link>
							<Link href='/updates'>
								<a className='p-2'>מה חדש</a>
							</Link>
							{/*<Link href='/#pricing'>*/}
							{/*	<a className='p-2'>מחירון</a>*/}
							{/*</Link>*/}
							<Link href='/terms'>
								<a className='p-2'>תנאי שימוש</a>
							</Link>
							<Link href='/privacy'>
								<a className='p-2'>פרטיות</a>
							</Link>
							<button onClick={() => window.$crisp.push(["do", "chat:open"])} className='p-2'>צור קשר</button>
							<Link href='/accessibility'>
								<a className='p-2'>הצהרת נגישות</a>
							</Link>
						</div>
						<div>{`זמן טוב © ${new Date().getFullYear()}`}</div>
						<div className='p-10 text-gray-400 max-w-3xl'>
							<a href='https://zmantov.co.il' className='p-2'>ניהול תורים לעסק</a>
							<a href='https://zmantov.co.il' className='p-2'>אפליקציה לניהול תורים</a>
							<a href='https://zmantov.co.il' className='p-2'>אפליקציה למספרות</a>
							<a href='https://zmantov.co.il' className='p-2'>אפליקציה למעצבי שיער</a>
							<a href='https://zmantov.co.il' className='p-2'>אפליקציה לקוסמטיקאיות</a>
							<a href='https://zmantov.co.il' className='p-2'>אפליקציה לניהול יומן</a>
							<a href='https://zmantov.co.il' className='p-2'>אפליקציה לניהול תורים ולקוחות</a>
							<a href='https://zmantov.co.il' className='p-2'>אפליקציה לניהול יומן</a>
							<a href='https://zmantov.co.il' className='p-2'>אפליקציה להזמנת תורים</a>
							<a href='https://zmantov.co.il' className='p-2'>הזמנת תורים לעסק</a>
							<a href='https://zmantov.co.il' className='p-2'>אפליקציה לקביעת תורים</a>
							<a href='https://zmantov.co.il' className='p-2'>אפליקציה למכון יופי</a>
							<a href='https://zmantov.co.il' className='p-2'>אפליקציה למאמני כושר</a>
							<a href='https://zmantov.co.il' className='p-2'>ניהול יומן וקביעת תורים</a>
							<a href='https://zmantov.co.il' className='p-2'>יומן לניהול תורים</a>
							<a href='https://zmantov.co.il' className='p-2'>מערכת לניהול תורים ולקוחות</a>
							<a href='https://zmantov.co.il' className='p-2'>קביעת תורים וניהול יומן</a>
							<a href='https://zmantov.co.il' className='p-2'>ניהול תורים ותזכורות</a>
							<a href='https://zmantov.co.il' className='p-2'>אפליקציה לקביעת תורים ללקוח</a>
						</div>
					</div>
				</footer>
			</div>
		</>
	);
}

export default Layout;
